<template>
  <div>
    <div class="statistical-bottom">
      <div class="statistical-bottom-table">
        <div class="statistical-bottom-table-title space-between">
          <div class="statistical-bottom-table-title-left">详细数据</div>
          <div class="statistical-bottom-table-title-right" @click="downtSatisticsEx(1)">
            <img src="~assets/images/index/down.png" alt="" class="down" />
            <img src="~assets/images/index/down-hover.png" alt="" class="down-hover" />
            导出Excel
          </div>
        </div>
        <div class="table">
          <el-table
            :data="statisticsTableData"
            :header-cell-style="{ background: '#F6F8F9', fontSize: '14px', fontWeight: '400', color: '#1C1F21' }"
            :cell-style="{ fontSize: '14px', fontWeight: '400', color: '#71777D' }"
            stripe
            style="width: 100%"
            id="downsatistics"
          >
            <el-table-column prop="title" label="标题" show-overflow-tooltip> </el-table-column>
            <el-table-column prop="start_time" label="直播日期" width="160px">
              <template slot-scope="scope">
                <div>{{ createdAt(scope.row.start_time) }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="participants_num" sortable label="参会人数" align="center" width="110px"> </el-table-column>
            <el-table-column prop="watch_num" sortable label="观看人数" align="center" width="110px"> </el-table-column>
            <el-table-column prop="playback_read_man_count" sortable label="回放观看人数" align="center" width="130px"> </el-table-column>
            <el-table-column prop="views_num" sortable label="回放播放次数" align="center" width="130px"> </el-table-column>
            <el-table-column label="操作" align="center" width="90">
              <template slot-scope="scope">
                <el-button type="text" size="small" class="table-edit" @click="goDetail(scope.row)">详细分析</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <el-pagination
          v-if="statisticsTableData.length > 0 && statisticsTotal > 10"
          class="page"
          background
          @size-change="StatisticshandleSizeChange"
          @current-change="StatisticshandleCurrentChange"
          :current-page="statisticsPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="statisticsPageSize"
          layout=" prev, pager, next,total, jumper"
          :total="statisticsTotal"
        >
        </el-pagination>
      </div>
    </div>
    <div class="statistical-bottom">
      <div class="statistical-bottom-table">
        <div class="statistical-bottom-table-title space-between">
          <div class="statistical-bottom-table-title-left">分日报表</div>
          <div class="statistical-bottom-table-title-right vertical-center" @click="downtSatisticsEx(2)">
            <img src="~assets/images/index/down.png" alt="" class="down" />
            <img src="~assets/images/index/down-hover.png" alt="" class="down-hover" />
            导出Excel
          </div>
        </div>
        <div class="table">
          <el-table
            :data="dayTableData"
            :header-cell-style="{ background: '#F6F8F9', fontSize: '14px', fontWeight: '400', color: '#1C1F21' }"
            :cell-style="{ fontSize: '14px', fontWeight: '400', color: '#71777D' }"
            stripe
            style="width: 100%"
            id="downday"
          >
            <el-table-column prop="date" label="日期" width="150"> </el-table-column>
            <el-table-column prop="participants_man_count" sortable label="参会人数" align="center"> </el-table-column>
            <el-table-column prop="live_read_man_count" sortable label="观看人数" align="center"> </el-table-column>
            <el-table-column prop="playback_read_man_count" sortable label="回放观看人数" align="center"> </el-table-column>
            <el-table-column prop="playback_read_count" sortable label="回放播放次数" align="center"> </el-table-column>
          </el-table>
        </div>

        <el-pagination
          v-if="dayTableData.length > 0 && dayTotal > 10"
          class="page"
          background
          @current-change="DayhandleCurrentChange"
          :current-page="dayPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-="dayPageSize"
          layout=" prev, pager, next,total, jumper"
          :total="dayTotal"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      value: 0,
      radio: 0,
      noDow: false,
      isShow: true,
      activeName: 'first',
      all: true,
      single: false,
      statisticsPage: 1,
      statisticsPageSize: 10,
      statisticsTotal: 0,

      dayPage: 1,
      dayPageSize: 10,
      dayTotal: 0,
      options: [
        {
          value: 0,
          label: '最近7天',
        },
        {
          value: 1,
          label: '最近14天',
        },
        {
          value: 2,
          label: '最近30天',
        },
        {
          value: 3,
          label: '最近90天',
        },
      ],
      statisticsTableData: [],
      dayTableData: [],
      circle_uuid: '',
    };
  },
  created() {
    this.circle_uuid = localStorage.getItem('circle-uuid');
  },
  mounted() {
    this.getStatisticsList();
    this.getDayStatisticsList();
    // this.drawLine();
  },
  methods: {
    // 直播时间切分
    createdAt(time) {
      return time.slice(0, 10);
    },
    // 下载
    downtSatisticsEx(type) {
      let url = `${this.$api.liveExport}?type=${type}`;
      this.$http.get(url, true).then((res) => {
        console.log(res);
        window.open(res.data.data);
      });
    },

    StatisticshandleSizeChange() {},
    // 分页
    StatisticshandleCurrentChange(val) {
      this.statisticsPage = val;
      getStatisticsList();
    },
    DayhandleCurrentChange(val) {
      this.dayPage = val;
      this.getDayStatisticsList();
    },
    // 获取列表
    getStatisticsList() {
      let url = `${this.$api.conference}?page=${this.statisticsPage}&pageSize=${this.statisticsPageSize}&circle_uuid=${this.circle_uuid}`;
      this.$http.get(url, true).then((res) => {
        console.log(res);
        if (res.data.success) {
          this.statisticsTableData = res.data.data.data;
          this.statisticsTotal = res.data.data.meta.total;
          this.$emit('getTotal', this.statisticsTotal);
        }
      });
    },
    // 获取分日列表
    getDayStatisticsList() {
      let url = `${this.$api.dayDate}?page=${this.dayPage}&pageSize=${this.dayPageSize}&circle_uuid=${this.circle_uuid}`;
      this.$http.get(url, true).then((res) => {
        console.log(res);
        if (res.data.success) {
          this.dayTableData = res.data.data.data;
          this.dayTotal = res.data.data.meta.total;
        }
      });
    },
    goDetail(row) {
      console.log(row);
      this.$router.push({
        path: `/analysis/live/detaile?title=${row.title}&id=${row.id}`,
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import '~assets/less/data/content/index.less';
</style>
