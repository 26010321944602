<template>
  <div v-loading="loading">
    <!--    上部开始-->
    <div class="statistical-top">
      <el-tabs v-model="activeName" class="el-nav">
        <el-tab-pane label="直播分析" name="first">
          <div class="statistical-center">
            <div class="btn-list vertical-center">
              <div class="left">数据总览</div>
              <div class="btn-list-right">
                共 <span>{{ courseTotal }}</span> 个直播
              </div>
            </div>
            <div class="statistical-center-list space-between">
              <div class="statistical-center-item live">
                <div class="statistical-center-item-title">参会人员总数</div>
                <div class="statistical-center-item-num">
                  {{ Number(total.participants_num) }}
                </div>

                <div class="statistical-center-item-foot">
                  昨日：
                  <el-tag :class="Number(yesterday_data.participants_man_count) === 0 ? 'noBg' : Number(yesterday_data.participants_man_count) > 0 ? 'blueBg' : 'redBg'"
                    >{{ Number(yesterday_data.participants_man_count) === 0 ? '' : Number(yesterday_data.participants_man_count) > 0 ? '+' : '-'
                    }}{{ yesterday_data.participants_man_count }}</el-tag
                  >
                </div>
                <el-divider></el-divider>
                <div class="statistical-center-item-jun">
                  <span>本周日均参会量：{{ week_data.participants_man_count }}</span>
                </div>
              </div>
              <div class="statistical-center-item live">
                <div class="statistical-center-item-title">观看人员总数</div>
                <div class="statistical-center-item-num">
                  {{ Number(total.watch_num) }}
                </div>

                <div class="statistical-center-item-foot">
                  昨日：
                  <el-tag class="noBg">
                    <el-tag :class="Number(yesterday_data.live_read_man_count) === 0 ? 'noBg' : Number(yesterday_data.live_read_man_count) > 0 ? 'blueBg' : 'redBg'"
                      >{{ Number(yesterday_data.live_read_man_count) === 0 ? '' : Number(yesterday_data.live_read_man_count) > 0 ? '+' : '-'
                      }}{{ yesterday_data.live_read_man_count }}</el-tag
                    >
                  </el-tag>
                </div>
                <el-divider></el-divider>
                <div class="statistical-center-item-jun">
                  <span>本周日均观看量：{{ week_data.live_read_man_count }}</span>
                </div>
              </div>
              <div class="statistical-center-item live">
                <div class="statistical-center-item-title">回放观看人数</div>
                <div class="statistical-center-item-num">
                  {{ Number(total.playback_read_man_count) }}
                </div>

                <div class="statistical-center-item-foot">
                  昨日：
                  <el-tag :class="Number(yesterday_data.playback_read_man_count) === 0 ? 'noBg' : Number(yesterday_data.playback_read_man_count) > 0 ? 'blueBg' : 'redBg'"
                    >{{ Number(yesterday_data.playback_read_man_count) === 0 ? '' : Number(yesterday_data.playback_read_man_count) > 0 ? '+' : '-'
                    }}{{ yesterday_data.playback_read_man_count }}</el-tag
                  >
                </div>
                <el-divider></el-divider>
                <div class="statistical-center-item-jun">
                  <span>本周日均观看量：{{ week_data.playback_read_man_count }}</span>
                </div>
              </div>
              <div class="statistical-center-item live">
                <div class="statistical-center-item-title">回放播放次数</div>
                <div class="statistical-center-item-num">
                  {{ Number(total.views_num) }}
                </div>

                <div class="statistical-center-item-foot">
                  昨日：
                  <el-tag :class="Number(yesterday_data.playback_read_count) === 0 ? 'noBg' : Number(yesterday_data.playback_read_count) > 0 ? 'blueBg' : 'redBg'"
                    >{{ Number(yesterday_data.playback_read_count) > 0 ? '+' : '' }}{{ Number(yesterday_data.playback_read_count) }}</el-tag
                  >
                </div>
                <el-divider></el-divider>
                <div class="statistical-center-item-jun">
                  <span>本周日均播放量：{{ week_data.playback_read_count }}</span>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div class="el-nav-right">
        每天
        <span>1</span>
        点左右更新前一日数据
      </div>
    </div>

    <!--上部结束-->

    <div>
      <!--全部-->
      <live @getTotal="getTotal" ref="course" v-if="activeIndex === 0"></live>
    </div>
  </div>
</template>

<script>
import live from './components/live';
export default {
  components: {
    live: live,
  },
  name: 'Index',
  data() {
    return {
      activeName: 'first',
      activeIndex: 0,
      readingsNum: 698457,
      likesNum: 15,
      commentsNum: 0,
      cocollectionsNum: 0,
      readingsOldNum: 0,
      likesOldNum: 15,
      commentsOldNum: -2,
      cocollectionsOldNum: 0,
      activeIndex: 0,
      yesterday_data: {},
      week_data: {},
      total: {},
      courseTotal: 0,
      tableData: [],
      circle_uuid: '',
      loading: false,
    };
  },
  created() {
    this.circle_uuid = localStorage.getItem('circle-uuid');
    this.activeIndex = 0;
    switch (this.$route.query.type) {
      case 'article':
        this.activeIndex = 1;
        break;
      case 'video':
        this.activeIndex = 2;
        break;
      case 'replay':
        this.activeIndex = 3;
        break;
      case 'posts':
        this.activeIndex = 4;
        break;
      default:
        this.activeIndex = 0;
    }
  },

  mounted() {
    this.getOverView();
    // 清空路由参数
    this.$router.push({ query: {} });
    this.getTotal();
  },
  methods: {
    getTotal(val) {
      if (val) {
        this.courseTotal = val;
      } else {
        this.courseTotal = 0;
      }
    },
    // 获取数据总览
    getOverView() {
      this.loading = true;
      let url = `${this.$api.overview}?circle_uuid=${this.circle_uuid}`;
      this.$http.get(url, true).then((res) => {
        console.log(res);
        if (res.data.success) {
          this.loading = false;
          this.yesterday_data = res.data.data.yesterday_data;
          this.week_data = res.data.data.week_data;
          this.total = res.data.data.total;
        }
      });
    },
    isNullorEmpty(str) {
      return str === '' || str === null || str === undefined || isNaN(str);
    },

    choseBtn(index) {
      this.activeIndex = index;
    },
  },
};
</script>

<style lang="less" scoped>
@import '~assets/less/data/content/index.less';
/deep/.el-tabs__header {
  margin: 0;
}
.btn-list {
  .left {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #71777d;
  }
}
.el-nav {
  .statistical-center {
    .statistical-center {
      margin-top: 30px;
    }
  }
}
</style>
